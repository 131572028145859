import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './index.css';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	ignoreErrors: [
		// a network error
		/^Failed to fetch.*/i,
		/^Failed to load.*/i,
		// Safari seems to do that
		/Load failed.*/i,
	],
});

// This error happens when a new version is deployed while users are on or have cached the old version
// This would lead to "Failed to fetch dynamically imported module" errors
window.addEventListener('vite:preloadError', (e) => {
	console.log('vite:preloadError - reloading page', e);
	e.preventDefault();
	window.location.reload(); // refresh the page
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<AudioContextForcer />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);

function AudioContextForcer() {
	// ensure audio context is always active, so we don't run into autoplay issues
	return <audio src="/silence.mp3" autoPlay loop />;
}
